//
//
//
//
//
//
import { isAuthenticated } from "@/core/services/jwt.service";
export default {
  beforeCreate: function beforeCreate() {
    if (isAuthenticated()) {
      this.$router.push({
        name: "dashboard"
      });
    }
  }
};